import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button, Spinner } from 'evergreen-ui';
import { AreaChart, XAxis, YAxis, Tooltip, Area } from 'recharts';
import Select from 'react-select';

import _, { M } from 'constants/i18n';
import { dateStrToLocaleStr } from 'utils/strings';
import { useFetchCadenceQuery, usePatchCadenceMutation } from 'api/cadence';
import { useAppDispatch, useAppSelector } from 'hooks';
import { setEditingCadence } from 'store/cadence/slice';
import { editingCadenceHasChanged, getEditingCadence } from 'store/cadence/selector';
import { TrackEventNames, tracker } from 'utils/tracking';
import { useCadenceAnalyticsQuery } from 'api/analytics';
import { CadenceEventTypes } from 'types/cadence';

import './style.css';


const StartedLineChart = ({eventsOverTime}: {eventsOverTime: Array<Array<string | Record<CadenceEventTypes, number>>>}) => {
  if (eventsOverTime.length === 0) return null;

  const chartData = eventsOverTime.map(([datestr, value]) => {
    const eventCount = (value as Record<CadenceEventTypes, number>);
    return {
      datestr,
      started: eventCount.enrolled,
      hubspotSyncs: eventCount.hubspot_activity_created + eventCount.hubspot_contact_created,
      emailsSent: eventCount.email_send,
      emailsOpened: eventCount.email_open,
    };
  });

  const sidebarWidthsAndPadding = 376;

  return (
    <AreaChart width={window.innerWidth - sidebarWidthsAndPadding} height={250} data={chartData}>
      <defs>
        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
          <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
        </linearGradient>
        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
          <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
        </linearGradient>
        <linearGradient id="colorEo" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#12caaa" stopOpacity={0.8}/>
          <stop offset="95%" stopColor="#12caaa" stopOpacity={0}/>
        </linearGradient>
        <linearGradient id="colorHs" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#ff5c35" stopOpacity={0.8}/>
          <stop offset="95%" stopColor="#ff5c35" stopOpacity={0}/>
        </linearGradient>
      </defs>
      <XAxis dataKey="datestr" />
      <YAxis />
      <Tooltip />
      {/* TODO: i18n */}
      <Area type="monotone" dataKey="hubspotSyncs" name="HubSpot contacts & activities created" stroke="#ff5c35" fillOpacity={1} fill="url(#colorHs)" />
      <Area type="monotone" dataKey="started" name="Cadence enrollments" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
      <Area type="monotone" dataKey="emailsSent" name="Emails sent" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
      <Area type="monotone" dataKey="emailsOpened" name="Emails opened" stroke="#12caaa" fillOpacity={1} fill="url(#colorEo)" />
    </AreaChart>
  );
};

const windowOptions = [
  {label: 'Last 14 days', value: 14},
  {label: 'Last 30 days', value: 30},
  {label: 'Last 60 days', value: 60},
  {label: 'Last 120 days', value: 120},
];


const CadencePerformance = () => {
  const { cadenceId: cadenceIdStr='' } = useParams<{cadenceId: string}>();
  const cadenceId = parseInt(cadenceIdStr);
  const [window, setWindow] = useState(windowOptions[1]);
  const nav = useNavigate();
  const dispatch = useAppDispatch();
  const [patch, patchRes] = usePatchCadenceMutation();
  const saving = patchRes.isLoading;
  const editingCadence = useAppSelector(getEditingCadence);
  const {
    isFetching,
    isLoading,
    isError: error,
    data,
  } = useFetchCadenceQuery(cadenceId);
  const hasChanged = useAppSelector(s => editingCadenceHasChanged(s, data?.cadence));

  const {
    data: analyticsData,
    isLoading: loadingAnalytics,
  } = useCadenceAnalyticsQuery({window: window.value, cadenceId});


  const loading = isLoading || isFetching;

  useEffect(() => {
    const noEditingCadence = editingCadence === null;
    const editingCadenceIsNew = data && editingCadence !== null && data?.cadence.id !== editingCadence.id;
    if ((noEditingCadence || editingCadenceIsNew) && data?.cadence) {
      dispatch(setEditingCadence(data.cadence));
    }
  }, [data, dispatch, editingCadence]);

  if (loading || loadingAnalytics) {
    return <Spinner delay={100}>{_(M.LOADING)}</Spinner>
  }

  if (!data || error) {
    return <div>{_(M.ERROR)}</div>
  }

  const onSaveCadence = async () => {
    if (saving || !editingCadence) return;

    patch(editingCadence).then(() => tracker.track(TrackEventNames.PC));
  };

  const onArchiveCadence = async () => {
    try {
      await patch({
        ...cadence,
        status: cadence.status === 'archived' ? 'active' : 'archived',
      });
      tracker.track(TrackEventNames.PC);
    } catch (error) {
      // TODO: log error
      console.warn('failed to archive cadence', error);
    }
  };

  const cadence = data.cadence;

  return <div className='view-cadence--container'>
    <div className="view-cadence--header">
      <div>
        <h2 className="view-cadence--title">{cadence.name}</h2>
        <div className="view-cadence--subtitle">{cadence.description}</div>
      </div>
      <div className="view-cadence--actions">
        <Button isLoading={saving || isFetching} disabled={!hasChanged} onClick={onSaveCadence} appearance='primary'>{_(M.SAVE)}</Button>
        <Button
          appearance="primary"
          intent="success"
          onClick={() => nav(`/cadences/${cadenceId}/enroll`)}
        >
          {_(M.ENROLL)}
        </Button>
        <Button onClick={() => nav(`/cadences/${cadenceId}/edit`)}>{_(M.NAV_CADENCE_EDIT)}</Button>
        <Button
          onClick={onArchiveCadence}
        >{cadence.status === 'archived' ? _(M.BUTTON_CADENCE_UNARCHIVE) : _(M.BUTTON_CADENCE_ARCHIVE)}</Button>
      </div>
    </div>
    <div className="view-cadence-dates--container">
      <div className="view-cadence-dates--date">
        <div className="view-cadence-dates--label">{_(M.CADENCE_SETTINGS_CREATED_AT)}</div>
        <div className="view-cadence-dates--value">{dateStrToLocaleStr(cadence.created_at)}</div>
      </div>
      <div className="view-cadence-dates--date">
        <div className="view-cadence-dates--label">{_(M.CADENCE_SETTINGS_UPDATED_AT)}</div>
        <div className="view-cadence-dates--value">{dateStrToLocaleStr(cadence.updated_at)}</div>
      </div>
    </div>
    <div>
      <div className="cadence-performance-chart-header--container">
        <h3 className="cadence-performance-chart-header--title">{_(M.CADENCE_PERFORMANCE_TITLE)}</h3>
        <Select
          value={window}
          options={windowOptions}
          isClearable={false}
          onChange={d => d && setWindow(d)}
          menuPortalTarget={document.body}
          styles={{ control: (base) => ({...base, fontSize: '12px'}), menuPortal: (base) => ({ ...base, fontSize: '12px', zIndex: 9999 }) }}
        />
      </div>
      <div style={{marginTop: 40}}>
        {analyticsData && <StartedLineChart eventsOverTime={analyticsData.events_over_time}/>}
      </div>
    </div>
  </div>;
};


export default CadencePerformance;