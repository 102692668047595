export enum Messages {
  NEW_CADENCE = 'button.create_new_cadence',
  NEW_CONTACT_LIST = 'button.create_new_contact_list',
  CONFIRM_DELETE_CONTACT_LIST = 'button.confirm_delete_contact_list',
  CONFIRM_CANNOT_UNDO = 'common.confirm_cannot_undo',

  ERROR_CADENCE_NOT_FOUND = 'error.cadence_not_found',
  ERROR_BAD_LOGIN = 'error.email_password_incorrect',
  ERROR_INVALID_EMAIL = 'error.invalid_email_address',
  ERROR_SIGNUP_FAILED = 'error.signup_failed',
  ERROR_FIELD_REQUIRED = 'error.field_required',
  ERROR_SHORT_PW = 'error.short_password',

  ASK_CREATE_ACCOUNT = 'ask.dont_have_account',
  ASK_FORGOT_PW = 'ask.forgot_password',
  ASK_TRY_AGAIN = 'ask.try_again',
  ASK_LOGIN = 'ask.login',

  NAV_SIGNUP = 'nav.signup',
  NAV_RESET_PW = 'nav.reset_pw',
  NAV_LOGIN = 'nav.login',
  NAV_SETTINGS = 'nav.settings',
  NAV_ACCOUNTS = 'nav.accounts',
  NAV_PROFILE = 'nav.profile',
  NAV_NOTIF_SETTINGS = 'nav.notif_settings',
  NAV_BILLING_SETTINGS = 'nav.billing_settings',
  NAV_LOGOUT = 'nav.logout',
  NAV_CADENCE_LIST = 'nav.cadence_list',
  NAV_CADENCES = 'nav.cadences',
  NAV_THREADS = 'nav.threads',
  NAV_THREADS_LIST = 'nav.threads_list',
  NAV_HOME = 'nav.home',
  NAV_CADENCE = 'nav.cadence',
  NAV_CADENCE_EDIT = 'nav.cadence_edit',
  NAV_CADENCE_ENROLLMENTS = 'nav.cadence_enrollments',
  NAV_CONTACTS = 'nav.contacts',
  NAV_CONTACT_LISTS = 'nav.contact_lists',
  NAV_MGR_METRICS = 'nav.mgr_metrics',
  NAV_INBOXES = 'nav.inboxes',
  NAV_UPSELL_TRIAL = 'nav.upsell_trial',
  NAV_TRIAL_EXPIRES_IN = 'nav.trial_expires_in',

  BACK = 'common.back',
  ID = 'common.id',
  SUCCESS = 'common.success',
  FAILED_TO_SAVE = 'common.failed_to_save',
  SAVE = 'common.save',
  OR = 'common.or',
  AND = 'common.and',
  AT = 'common.at',
  TO = 'common.to',
  EMAIL = 'common.email',
  PW = 'common.password',
  FIRST_NAME = 'common.first_name',
  LAST_NAME = 'common.last_name',
  TOS_AGREE = 'common.terms_of_service_agree',
  TOS = 'common.terms_of_service_name',
  LOADING = 'common.loading',
  REFRESHING = 'common.refreshing',
  ERROR = 'common.error',
  WEEK = 'common.week',
  WEEKS = 'common.weeks',
  DAY = 'common.day',
  DAYS = 'common.days',
  HOUR = 'common.hour',
  HOURS = 'common.hours',
  MINUTE = 'common.minute',
  MINUTES = 'common.minutes',
  CADENCE = 'common.cadence',
  CADENCES = 'common.cadences',
  ANY = 'common.any',
  ALL = 'common.all',
  CONFIRM = 'common.confirm',
  NEXT = 'common.next',
  CANCEL = 'common.cancel',
  LABELS = 'common.labels',
  ACTIVE = 'common.active',
  ARCHIVE = 'common.archive',
  UNARCHIVE = 'common.unarchive',
  ARCHIVED = 'common.archived',
  NAME = 'common.name',
  DESCRIPTION = 'common.description',
  CONTACT = 'common.contact',
  CONTACTS = 'common.contacts',
  ACTION = 'common.action',
  UNENROLL = 'common.unenroll',
  LAST_ENROLLMENT_STEP = 'data.last_enrollment_step',
  SELECT_PLACEHOLDER = 'common.select_placeholder',
  INSTALL = 'common.install',
  PREVIEW = 'common.preview',
  ORG = 'common.organization',
  CREATED_AT = 'common.created_at',
  ENROLL = 'common.enroll',
  EDIT = 'common.edit',
  DELETE = 'common.delete',
  MORE_INFO = 'common.more_info',

  NEW_CADENCE_NAME = 'data.new_cadence_name',
  NEW_CADENCE_DESC = 'data.new_cadence_description',
  CADENCE_START = 'data.cadence_start',
  NODE_TYPE_WAIT = 'data.node_action_type_wait',
  NODE_TYPE_SEND_EMAIL = 'data.node_action_type_send_email',
  NODE_TYPE_BRANCH = 'data.node_action_type_branch',
  NODE_TYPE_APPLY_LABEL = 'data.node_action_type_apply_label',
  CADENCE_ADD_STEP = 'data.cadence_add_step',
  EMAIL_SUBJECT = 'data.email_subject',
  EMAIL_WARN_EMPTY_LINK = 'data.email.body.empty_link',
  EMAIL_REPLY_STAYS_SAME = 'data.email_subject_reply_stays_same',
  EMAIL_SEND_AS_REPLY = 'data.email_send_as_reply',
  EMAIL_PAUSE_BEFORE_SENDING = 'data.email_pause_before_sending',
  EMAIL_SEND_ON_BIZ_DAYS = 'data.email_send_on_business_days',
  EMAIL_BODY = 'data.email_body',
  STEP_TYPE = 'data.step_type',
  STEPS = 'data.steps',
  EDIT_STEP = 'data.edit_cadence_step',
  CONDITIONS = 'data.cadence_conditions',
  CONDITIONS_SUBTITLE = 'data.cadence_conditions_subtitle',
  CONDITIONS_NEEDED = 'data.cadence_conditions_needed',
  ADD_CONDITION = 'data.cadence_add_condition',
  ADD_ANOTHER_CONDITION = 'data.cadence_add_another_condition',
  CONDITIONS_FAIL_PROMPT = 'data.cadence_conditions_alternative_step',
  BUTTON_CADENCE_EDIT = 'button.cadence_edit',
  BUTTON_CADENCE_ARCHIVE = 'button.cadence_archive',
  BUTTON_CADENCE_UNARCHIVE = 'button.cadence_unnarchive',
  SEND_EMAIL_TO_RESUME_CADENCE = 'prompt.send_email_to_resume_cadence',
  CREATE_CONTACT_LIST_FROM_CSV = 'prompt.contact_list.create_from_Csv',
  CONTACT_LIST_NAME = 'prompt.contact_list.name',
  CADENCES_TITLE_LEARN_MORE = 'prompt.cadences_title.learn_more',

  EMPTY_CADENCE_TITLE = 'empty.cadence_list_title',
  EMPTY_CADENCE_DESC = 'empty.cadence_list_description',

  EMPTY_CONTACTS_TITLE = 'empty.contacts_list_title',
  EMPTY_CONTACTS_DESC = 'empty.contacts_list_description',

  EMPTY_CONTACT_LISTS_TITLE = 'empty.contact_lists_title',
  EMPTY_CONTACT_LISTS_DESC = 'empty.contact_lists_description',

  NO_CONTACTS_IN_LIST_TITLE = 'empty.contacts_in_list_title',
  NO_CONTACTS_IN_LIST_DESC = 'empty.contacts_in_list_description',
  ADD_CONTACT_TO_LIST = 'add_contact_to_list',
  ADD_CONTACT_TO_LIST_LABEL = 'add_contact_to_list.label',

  HOME_HEADER = 'home.header',
  HOME_COMPARISON_NO_CHANGE = 'home.comparison_no_change',
  HOME_COMPARISON_UP = 'home.comparison_up',
  HOME_COMPARISON_DOWN = 'home.comparison_down',
  HOME_COMPARISON_PREV_WEEK = 'home.comparison_prev_week',
  HOME_TOPLINE_METRIC_HELP_1 = 'home.topline_metric_help_1',
  HOME_TOPLINE_METRIC_HELP_2 = 'home.topline_metric_help_2',
  HOME_ACTIVE_CADENCES_START = 'home.active_cadences_start',
  HOME_ACTIVE_CADENCES_END = 'home.active_cadences_end',
  HOME_VIEW_CADENCES = 'home.view_cadences',
  HOME_CONNECT_GOOGLE_ACCOUNT = 'home.connect_google_account',
  HOME_CHART_HEADER = 'home.chart_header',

  SIGNIN_REQUIRED = 'login_required',
  GENERIC_CONNECTION_SUCCESS = 'connector.oauth_success_generic',
  GENERIC_CONNECTION_FAILED = 'connector.oauth_failed_generic',
  CONN_FAILED_GMAIL_SCOPES = 'connector.oauth_failed.gmail_scopes',
  CONN_FAILED_GDRIVE_SCOPES = 'connector.oauth_failed.gdrive_scopes',
  CONN_FAILED_GCAL_SCOPES = 'connector.oauth_failed.gcal_scopes',
  CONN_FAILED_NO_GOOGLE_SCOPES = 'connector.oauth_failed.no_google_scopes',
  CONN_FAILED_PARTIAL_GOOGLE_SCOPES = 'connector.oauth_failed.partial_google_scopes',
  CONN_FAILED_PROVIDER = 'connector.oauth_failed.provider_error',
  CONN_FAILED_500 = 'connector.oauth_failed.internal_error',
  CONN_AUTH_TITLE = 'connector.connection_page.title',
  CONN_AUTH_DESC = 'connector.connection_page.description',
  CONN_AUTH_LOADING = 'connector.connection_page.loading',
  CONN_AUTH_FAILED = 'connector.connection_page.failed',
  CONN_AUTH_TRY_AGAIN = 'connector.connection_page.try_again',
  CONN_AUTH_SUCCESS = 'connector.connection_page.success',

  EDIT_BIZ_HOURS_TITLE = 'cadence.settings.edit_biz_hrs.title',
  MANUALLY_ENROLL_TITLE = 'cadence.manually_enroll_title',
  ENROLL_SUCCESS = 'cadence.manually_enroll_success',
  SCHEDULE_ENROLL_LABEL = 'cadence.manually_enroll_schedule_label',
  SCHEDULE_ENROLL_HELP = 'cadence.manually_enroll_schedule_help',
  CHOOSE_CADENCE_TEMPLATE_TITLE = 'new_cadence.choose_template_title',
  NEW_CADENCE_NAME_FOLLOW_UP = 'new_cadence.follow_up_template_name',
  NEW_CADENCE_DESC_FOLLOW_UP = 'new_cadence.follow_up_template_description',
  ENROLL_DIALOG_HELP_LINK = 'cadence.manually_enroll.learn_more',

  REMOVE_OTHER_LABELS = 'data.remove_other_labels',

  CONNECT_HUBSPOT = 'oauth.connect_hubspot',
  CONNECT_ACCOUNT = 'settings.connect_account',
  YOUR_ACCOUNTS = 'settings.list_your_accounts',

  CREATE_HS_CONTACTS_LABEL = 'settings.create_hubspot_contacts_label',
  CREATE_HS_CONTACTS_DESC = 'settings.create_hubspot_contacts_description',
  LOG_HS_EMAIL_SENDS_LABEL = 'settings.log_hubspot_email_sends_label',
  LOG_HS_EMAIL_SENDS_DESC = 'settings.log_hubspot_email_sends_description',
  PULL_HS_CTC_PROPS_LABEL = 'settings.pull_hubspot_contact_properties_label',
  PULL_HS_CTC_PROPS_DESC = 'settings.pull_hubspot_contact_properties_description',

  INSERT_TEMPLATE_VAR_TITLE = 'composer.insert_template_var_dialog_title',
  INSERT_TEMPLATE_VAR_LABEL = 'composer.insert_template_var_label',
  INSERT_TEMPLATE_VAR_FALLBACK_LABEL = 'composer.insert_template_var_fallback_label',
  INSERT_TEMPLATE_VAR_FALLBACK_HELP = 'composer.insert_template_var_fallback_help',

  CSV_UPLOAD_HEADER = 'csv_upload.header',
  CSV_UPLOAD_DESCRIPTION = 'csv_upload.description',
  CSV_UPLOAD_INVALID_NO_CONTENT = 'csv_upload.invalid_no_content',
  CSV_UPLOAD_INVALID_NO_EMAIL_COL = 'csv_upload.invalid_no_email_col',
  CSV_UPLOAD_INVALID_BAD_ROWS = 'csv_upload.invalid_bad_rows',
  CSV_UPLOAD_INVALID = 'csv_upload.invalid',
  CSV_UPLOAD_ERRORS = 'csv_upload.errors',

  CONTACT_DRAWER_TITLE = 'contact_drawer.title',
  CONTACT_DRAWER_LINK = 'contact_drawer.link',
  CONTACT_DRAWER_HANDLES_TITLE = 'contact_drawer.handled_title',
  CONTACT_DRAWER_PROPERTIES_TITLE = 'contact_drawer.properties_title',

  ONBOARDING_SKIP_TEXT = 'onboarding.skip',
  ONBOARDING_DONE_TEXT = 'onboarding.done',
  ONBOARDING_WELCOME_HEADER = 'onboarding.welcome_header',
  ONBOARDING_WELCOME_DESCRIPTION = 'onboarding.welcome_description',
  ONBOARDING_CONNECT_GOOGLE_HEADER = 'onboarding.connect_google_header',
  ONBOARDING_CONNECT_GOOGLE_DESCRIPTION = 'onboarding.connect_google_description',
  ONBOARDING_INSTALL_ADDON_HEADER = 'onboarding.install_addon_header',
  ONBOARDING_INSTALL_ADDON_DESC = 'onboarding.install_addon_desc',
  ONBOARDING_CONNECT_CRM_HEADER = 'onboarding.connect_crm_header',
  ONBOARDING_CONNECT_CRM_DESCRIPTION = 'onboarding.connect_crm_description',

  CADENCE_SETTINGS_TITLE = 'cadence.settings.title',
  CADENCE_PERFORMANCE_TITLE = 'cadence.performance.title',
  CADENCE_EMAIL_SEND_ON_BIZ_DAYS = 'cadence.settings.email_send_on_business_days',
  CADENCE_EMAIL_SEND_DURING_BIZ_HOURS = 'cadence.settings.only_send_email_during_biz_hours',
  CADENCE_EMAIL_SEND_AS_REPLIES = 'cadence.settings.email_send_as_replies',
  CADENCE_EMAIL_PAUSE_BEFORE_SEND = 'cadence.settings.email_pause_before_send',
  CADENCE_EMAIL_UNENROLL_ON_REPLY = 'cadence.settings.email_unenroll_on_reply',
  CADENCE_EMAIL_UNENROLL_ON_MTG = 'cadence.settings.email_unenroll_on_meeting',
  CADENCE_SETTINGS_CREATED_AT = 'cadence.settings.created_at_label',
  CADENCE_SETTINGS_UPDATED_AT = 'cadence.settings.updated_at_label',
  CADENCE_ENROLLMENT_OPTIONS_TITLE = 'cadence.settings.enrollment_options.title',
  CADENCE_ENROLLMENT_HS_LIST_TITLE = 'cadence.settings.enrollment_options.hs_list.title',
  CADENCE_ENROLLMENT_HS_LIST_DESC = 'cadence.settings.enrollment_options.hs_list.desc',
  CADENCE_ENROLLMENT_HS_LIST_SYNC_NOW = 'cadence.settings.enrollment_options.hs_list.sync_now',
  CADENCE_ENROLLMENT_HS_LIST_SYNC_SUCCESS = 'cadence.settings.enrollment_options.hs_list.sync_success',
  CADENCE_ENROLLMENT_HS_LIST_SYNC_TOO_FAST = 'cadence.settings.enrollment_options.hs_list.sync_too_fast',

  HOME_MERGE_CONTACTS_PROMPT = 'home.merge_contacts_prompt',
  MERGE_CONTACTS_DIALOG_TITLE = 'home.merge_contacts_dialog.title',
  MERGE_CONTACTS_DIALOG_DESC = 'home.merge_contacts_dialog.description',
  MERGE_CONTACTS_DIALOG_WARNING = 'home.merge_contacts_dialog.warning',
  MERGE_CONTACTS_DIALOG_PRIMARY_LABEL = 'home.merge_contacts_dialog.primary_contact_label',
  MERGE_CONTACTS_DIALOG_SECONDARY_LABEL = 'home.merge_contacts_dialog.secondary_contact_label',

  FREE_TRIAL_EXPIRED_TITLE = 'free_trial_expired.title',
  FREE_TRIAL_EXPIRED_DESC = 'free_trial_expired.description',
  FREE_TRIAL_EXPIRED_BTN = 'free_trial_expired.button',

  CADENCE_EVENT_EMAIL_OPEN_TITLE = 'cadence_event.email_open.title',
  CADENCE_EVENT_EMAIL_OPEN_DESC = 'cadence_event.email_open.desc',

  CADENCE_EVENT_ENROLLED_TITLE = 'cadence_event.enrolled.title',
  CADENCE_EVENT_ENROLLED_DESC = 'cadence_event.enrolled.desc',
  CADENCE_EVENT_ENROLLED_FROM_SITE = 'cadence_event.enrolled.from_site',
  CADENCE_EVENT_ENROLLED_FROM_GMAIL = 'cadence_event.enrolled.from_gmail',
  CADENCE_EVENT_ENROLLED_FROM_HS_LIST = 'cadence_event.enrolled.from_hs_list',

  CADENCE_EVENT_HS_CONTACT_CREATE_TITLE = 'cadence_event.hs_ctc_create.title',
  CADENCE_EVENT_HS_CONTACT_CREATE_DESC = 'cadence_event.hs_ctc_create.desc',
  CADENCE_EVENT_HS_CONTACT_CREATE_SUCCESS = 'cadence_event.hs_ctc_create.success',
  CADENCE_EVENT_HS_CONTACT_CREATE_ALREADY_EXISTS = 'cadence_event.hs_ctc_create.already_exists',
  CADENCE_EVENT_HS_CONTACT_CREATE_FAIL = 'cadence_event.hs_ctc_create.fail',

  CADENCE_EVENT_PAUSED_TITLE = 'cadence_event.paused.title',
  CADENCE_EVENT_PAUSED_DESC = 'cadence_event.paused.desc',
  CADENCE_EVENT_PAUSED_DRAFT = 'cadence_event.paused.draft',

  CADENCE_EVENT_HS_PROP_PULL_TITLE = 'cadence_event.hs_prop_pull.title',
  CADENCE_EVENT_HS_PROP_PULL_DESC = 'cadence_event.hs_prop_pull.desc',

  CADENCE_EVENT_EMAIL_SEND_TITLE = 'cadence_event.email_send.title',
  CADENCE_EVENT_EMAIL_SEND_DESC = 'cadence_event.email_send.desc',
  CADENCE_EVENT_EMAIL_SEND_CONTENT = 'cadence_event.email_send.content',

  CADENCE_EVENT_EMAIL_SCHEDULED_TITLE = 'cadence_event.email_biz_day_scheduled.title',

  CADENCE_EVENT_SCHEDULED_TITLE = 'cadence_event.scheduled.title',

  CADENCE_EVENT_HS_ACTIVITY_CREATE_TITLE = 'cadence_event.hs_activity_create.title',
  CADENCE_EVENT_HS_ACTIVITY_CREATE_DESC = 'cadence_event.hs_activity_create.desc',
  CADENCE_EVENT_HS_ACTIVITY_CREATE_SUCCESS = 'cadence_event.hs_activity_create.success',
  CADENCE_EVENT_HS_ACTIVITY_CREATE_FAILED = 'cadence_event.hs_activity_create.contact_not_found',

  CADENCE_EVENT_AUTOMATED_UNENROLL_TITLE = 'cadence_event.automated_unenroll.title',
  CADENCE_EVENT_AUTOMATED_UNENROLL_DESC = 'cadence_event.automated_unenroll.desc',
  CADENCE_EVENT_AUTOMATED_UNENROLL_BC_EMAIL = 'cadence_event.automated_unenroll.due_to_email',
  CADENCE_EVENT_AUTOMATED_UNENROLL_BC_MTG = 'cadence_event.automated_unenroll.due_to_meeting',

  VIEW_ENROLLMENT_TITLE = 'cadence_events.view_enrollment.title',
  VIEW_ENROLLMENT_EVENTS_TIMELINE = 'cadence_events.view_events.timeline',

  CADENCE_EVENT_FINISHED_TITLE = 'cadence_event.finished.title',
  CADENCE_EVENT_FINISHED_DESC = 'cadence_event.finished.desc',

  CADENCE_EVENT_LABELLED_TITLE = 'cadence_event.labelled.title',
  CADENCE_EVENT_LABELLED_DESC = 'cadence_event.labelled.desc',

  CADENCE_EVENT_CANCELLED_TITLE = 'cadence_event.cancelled.title',
  CADENCE_EVENT_CANCELLED_DESC = 'cadence_event.cancelled.desc',

  CONTACT_SUBNAV_SUMMARY = 'contact.subnav.summary',
  CONTACT_SUBNAV_EDIT = 'contact.subnav.edit',
  CONTACT_SUBNAV_METRICS = 'contact.subnav.metrics',
  CONTACT_SUBNAV_CADENCE_EVENTS = 'contact.subnav.events',

  CONTACT_EVENTS_NO_EVENTS = 'contact.events.no_events',

  SEND_TEST_EMAIL_BTN = 'email_editor.send_test_email',
  SEND_TEST_EMAIL_SUCCESS = 'email_editor.send_test_email.success',
  SEND_TEST_EMAIL_FAIL = 'email_editor.send_test_email.fail',
  SEND_EMAIL = 'email_editor.send_button.label',
  SAVING_DRAFT = 'email_editor.save_label.saving',
  SAVE_DRAFT_SUCCESS = 'email_editor.save_label.success',
  SAVE_DRAFT_FAILED = 'email_editor.save_label.failed',

  HUBSPOT_SETTINGS_TITLE = 'settings.hubspot.title',
}

export type Locale = Record<Messages, string>;