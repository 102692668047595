import { Locale, Messages } from './base';

const EN_US: Locale = {
  [Messages.ERROR_CADENCE_NOT_FOUND]: 'This cadence has been deleted or does not exist.',
  [Messages.NEW_CADENCE]: 'New Cadence',
  [Messages.NEW_CONTACT_LIST]: 'New List',
  [Messages.CONFIRM_DELETE_CONTACT_LIST]: 'Confirm delete list',
  [Messages.CONFIRM_CANNOT_UNDO]: 'Are you sure? You cannot undo this action',
  [Messages.ERROR_BAD_LOGIN]: 'Your email and/or password is incorrect.',
  [Messages.NAV_RESET_PW]: 'Reset password',
  [Messages.NAV_SIGNUP]: 'Sign up',
  [Messages.NAV_LOGIN]: 'Login',
  [Messages.NAV_THREADS]: 'Threads',
  [Messages.NAV_CONTACTS]: 'Contacts',
  [Messages.NAV_MGR_METRICS]: 'Manager metrics',
  [Messages.NAV_THREADS_LIST]: 'Threads',
  [Messages.NAV_UPSELL_TRIAL]: 'Upgrade your account!',
  [Messages.NAV_TRIAL_EXPIRES_IN]: 'Trial expires',
  [Messages.ASK_CREATE_ACCOUNT]: 'Don’t have an account?',
  [Messages.ASK_FORGOT_PW]: 'Forgot your password?',
  [Messages.ERROR_INVALID_EMAIL]: 'Please enter a valid email address',
  [Messages.ID]: 'id',
  [Messages.BACK]: 'Back',
  [Messages.OR]: 'or',
  [Messages.TO]: 'to',
  [Messages.EMAIL]: 'Email',
  [Messages.PW]: 'Password',
  [Messages.SAVE]: 'Save',
  [Messages.NEXT]: 'Next',
  [Messages.ERROR_SIGNUP_FAILED]: 'Failed to create account',
  [Messages.ASK_TRY_AGAIN]: 'Please try again.',
  [Messages.UNENROLL]: 'Unenroll',
  [Messages.SELECT_PLACEHOLDER]: 'Select...',
  [Messages.PREVIEW]: 'Preview',
  [Messages.EDIT]: 'edit',
  [Messages.DELETE]: 'delete',

  [Messages.FIRST_NAME]: 'First name',
  [Messages.LAST_NAME]: 'Last name',
  [Messages.ERROR_FIELD_REQUIRED]: 'Please fill out this field',
  [Messages.ERROR_SHORT_PW]: 'Password must be at least 12 characters',
  [Messages.TOS_AGREE]: 'By creating an account, you agree to Tiny Cadence’s',
  [Messages.TOS]: 'Terms of Service',
  [Messages.ASK_LOGIN]: 'Already have an accoount?',
  [Messages.NEW_CADENCE_NAME]: 'Empty cadence',
  [Messages.NEW_CADENCE_DESC]: 'A blank slate for all your creative workflow needs.',
  [Messages.EMPTY_CADENCE_TITLE]: "You don't have any cadences yet",
  [Messages.EMPTY_CADENCE_DESC]: 'Supercharge your day with a superpowered workflow. Set up your first cadence to start saving time.',
  [Messages.EMPTY_CONTACTS_DESC]: "You don't have any contacts yet",
  [Messages.EMPTY_CONTACTS_TITLE]: 'Connect an account to see your contacts',
  [Messages.NO_CONTACTS_IN_LIST_DESC]: 'Click below to add your first contact to this list',
  [Messages.NO_CONTACTS_IN_LIST_TITLE]: 'There are no contacts in this list',
  [Messages.ADD_CONTACT_TO_LIST]: 'Add contact',
  [Messages.ADD_CONTACT_TO_LIST_LABEL]: 'Add a contact to this list',
  [Messages.EMPTY_CONTACT_LISTS_DESC]: "You don't have any contact lists",
  [Messages.EMPTY_CONTACT_LISTS_TITLE]: 'Click the button below to create your first one',
  [Messages.LOADING]: 'loading',
  [Messages.REFRESHING]: 'refreshing data',
  [Messages.ERROR]: 'error',
  [Messages.SUCCESS]: 'Success',
  [Messages.FAILED_TO_SAVE]: 'Failed to save',
  [Messages.CADENCE]: 'cadence',
  [Messages.CADENCES]: 'cadences',
  [Messages.NAV_SETTINGS]: 'Settings',
  [Messages.NAV_ACCOUNTS]: 'Integrations',
  [Messages.NAV_PROFILE]: 'Profile',
  [Messages.NAV_NOTIF_SETTINGS]: 'Notifications',
  [Messages.NAV_BILLING_SETTINGS]: 'Billing',
  [Messages.NAV_LOGOUT]: 'Logout',
  [Messages.NAV_INBOXES]: 'Inbox',
  [Messages.NAV_CONTACT_LISTS]: 'Contact Lists',
  [Messages.LABELS]: 'Labels',
  [Messages.ACTIVE]: 'Active',
  [Messages.ARCHIVED]: 'Archived',
  [Messages.ARCHIVE]: 'archive',
  [Messages.UNARCHIVE]: 'unarchive',

  [Messages.NAV_CADENCE_LIST]: 'all cadences',
  [Messages.NAV_CADENCE]: 'cadence',
  [Messages.NAV_CADENCES]: 'Cadences',
  [Messages.NAV_HOME]: 'Home',
  [Messages.NAV_CADENCE_EDIT]: 'edit',
  [Messages.NAV_CADENCE_ENROLLMENTS]: 'enrollments',
  [Messages.BUTTON_CADENCE_EDIT]: 'edit',
  [Messages.BUTTON_CADENCE_ARCHIVE]: 'archive',
  [Messages.BUTTON_CADENCE_UNARCHIVE]: 'unarchive',

  [Messages.CREATE_CONTACT_LIST_FROM_CSV]: 'Create a contact list from this CSV',
  [Messages.CONTACT_LIST_NAME]: 'List name',
  [Messages.CADENCE_START]: 'Start',
  [Messages.NODE_TYPE_BRANCH]: 'Conditional branch',
  [Messages.NODE_TYPE_APPLY_LABEL]: 'Add label',
  [Messages.NODE_TYPE_SEND_EMAIL]: 'Send email',
  [Messages.NODE_TYPE_WAIT]: 'Delay',
  [Messages.STEPS]: 'Steps',
  [Messages.CADENCE_ADD_STEP]: 'Add step',
  [Messages.STEP_TYPE]: 'Step type',
  [Messages.EDIT_STEP]: 'Edit cadence step',
  [Messages.CONDITIONS]: 'Conditions',
  [Messages.CONDITIONS_SUBTITLE]: 'If...',
  [Messages.CONDITIONS_NEEDED]: 'Conditions needed',
  [Messages.ADD_CONDITION]: 'Add a condition',
  [Messages.ADD_ANOTHER_CONDITION]: 'Add another condition',
  [Messages.CONDITIONS_FAIL_PROMPT]: 'Add failure step',

  [Messages.WEEK]: 'week',
  [Messages.WEEKS]: 'weeks',
  [Messages.HOUR]: 'hour',
  [Messages.HOURS]: 'hours',
  [Messages.DAY]: 'day',
  [Messages.DAYS]: 'days',
  [Messages.MINUTE]: 'minute',
  [Messages.MINUTES]: 'minutes',
  [Messages.ANY]: 'any',
  [Messages.ALL]: 'all',
  [Messages.AND]: 'and',
  [Messages.AT]: 'at',
  [Messages.CONFIRM]: 'Confirm',
  [Messages.CANCEL]: 'Cancel',
  [Messages.NAME]: 'Name',
  [Messages.DESCRIPTION]: 'Description',
  [Messages.INSTALL]: 'Install',
  [Messages.CREATED_AT]: 'Created at',
  [Messages.ENROLL]: 'Enroll',

  [Messages.EMAIL_SUBJECT]: 'Subject',
  [Messages.EMAIL_WARN_EMPTY_LINK]: 'Warning! One or more links are invalid',
  [Messages.EMAIL_BODY]: 'Body',
  [Messages.EMAIL_REPLY_STAYS_SAME]: 'If the email is a reply, the subject must stay the same',
  [Messages.EMAIL_SEND_AS_REPLY]: 'Send as reply to previous email',
  [Messages.EMAIL_PAUSE_BEFORE_SENDING]: 'Allow me to edit and approve before sending email',
  [Messages.EMAIL_SEND_ON_BIZ_DAYS]: 'Send the email only on business days',

  [Messages.HOME_HEADER]: 'Welcome back',
  [Messages.HOME_COMPARISON_NO_CHANGE]: 'No change',
  [Messages.HOME_COMPARISON_UP]: 'Up',
  [Messages.HOME_COMPARISON_DOWN]: 'Down',
  [Messages.HOME_COMPARISON_PREV_WEEK]: 'from previous week',
  [Messages.HOME_TOPLINE_METRIC_HELP_1]: 'Emails sent from TinyCadence',
  [Messages.HOME_TOPLINE_METRIC_HELP_2]: 'Contacts and activities created in HubSpot',
  [Messages.HOME_ACTIVE_CADENCES_START]: 'You have',
  [Messages.HOME_ACTIVE_CADENCES_END]: 'active',
  [Messages.HOME_VIEW_CADENCES]: 'View Cadences',
  [Messages.HOME_CONNECT_GOOGLE_ACCOUNT]: 'Connect your Google account and start automating your outreach!',
  [Messages.HOME_CHART_HEADER]: 'TinyCadence activity summary',
  [Messages.SEND_EMAIL_TO_RESUME_CADENCE]: 'Send email to resume enrollment for contact',
  [Messages.CONTACT]: 'contact',
  [Messages.CONTACTS]: 'contacts',
  [Messages.ACTION]: 'action',
  [Messages.LAST_ENROLLMENT_STEP]: 'Last enrollment step',
  [Messages.ORG]: 'organization',

  [Messages.SIGNIN_REQUIRED]: 'You need to sign in to try again.',
  [Messages.GENERIC_CONNECTION_FAILED]: 'Failed to connect to your account.',
  [Messages.CONN_FAILED_GMAIL_SCOPES]: 'TinyCadence needs access to view your email to integrate with Gmail.',
  [Messages.CONN_FAILED_GDRIVE_SCOPES]: 'TinyCadence needs file information and activity permission to integrate with Google Drive.',
  [Messages.CONN_FAILED_GCAL_SCOPES]: 'TinyCadence needs to view your calendars and events to integrate with Google Calendar.',
  [Messages.CONN_FAILED_NO_GOOGLE_SCOPES]: 'No permissions were given for TinyCadence to integrate with Google.',
  [Messages.CONN_FAILED_PARTIAL_GOOGLE_SCOPES]: 'You only gave TinyCadence access to some of your Google account permissions. Try reconnecting with all permissions to add everything.',
  [Messages.CONN_FAILED_PROVIDER]: 'Provider failed to respond properly, please try again.',
  [Messages.CONN_FAILED_500]: 'TinyCadence failed to connect. Our team has been notified.',
  [Messages.CONN_AUTH_TITLE]: 'Syncing your account to TinyCadence',
  [Messages.CONN_AUTH_DESC]: 'Once your account is verified, you can immediately use the integration to in TinyCadence features.',
  [Messages.CONN_AUTH_LOADING]: 'Verifying connection',
  [Messages.CONN_AUTH_FAILED]: 'Connection failed. Feel free to try again.',
  [Messages.CONN_AUTH_TRY_AGAIN]: 'Try again',
  [Messages.CONN_AUTH_SUCCESS]: 'Hooray! Your account was connected successfully.',
  [Messages.GENERIC_CONNECTION_SUCCESS]: 'Successfully connected your account to TinyCadence 🎉`',

  [Messages.EDIT_BIZ_HOURS_TITLE]: 'Edit business hours for sending emails from this cadence',
  [Messages.MANUALLY_ENROLL_TITLE]: 'Enroll contacts in this cadence',
  [Messages.CHOOSE_CADENCE_TEMPLATE_TITLE]: 'Select the template for your new cadence',
  [Messages.NEW_CADENCE_NAME_FOLLOW_UP]: 'Follow up after an email introduction',
  [Messages.NEW_CADENCE_DESC_FOLLOW_UP]: 'Reply to the person who introduced you and follow up after a couple days if there was no response',

  [Messages.REMOVE_OTHER_LABELS]: 'Remove other labels',
  [Messages.ENROLL_SUCCESS]: 'Successfully enrolled!',
  [Messages.SCHEDULE_ENROLL_LABEL]: 'Schedule enrollment',
  [Messages.SCHEDULE_ENROLL_HELP]: 'leave blank to enroll immediately',
  [Messages.CONNECT_HUBSPOT]: 'Connect HubSpot',
  [Messages.CONNECT_ACCOUNT]: 'Connect an account',
  [Messages.YOUR_ACCOUNTS]: 'Your accounts',

  [Messages.CREATE_HS_CONTACTS_LABEL]: 'Create new contacts in HubSpot',
  [Messages.CREATE_HS_CONTACTS_DESC]: 'When creating a contact in TinyCadence, check if the contact exists in HubSpot. Create a new contact in HubSpot if there is no contact with the given email address.',
  [Messages.LOG_HS_EMAIL_SENDS_LABEL]: 'Log email sends as contact activity in HubSpot',
  [Messages.LOG_HS_EMAIL_SENDS_DESC]: 'When TinyCadence sends an email to a contact, find the contact via email address in HubSpot. If one exists, log the email that is sent as an activity in the contact’s timeline. (Note: if you use the HubSpot Gmail Extension, disable this to prevent duplicated timeline activity)',
  [Messages.PULL_HS_CTC_PROPS_LABEL]: 'Populate TinyCadence contact properties with HubSpot data',
  [Messages.PULL_HS_CTC_PROPS_DESC]: 'When creating a contact in TinyCadence, this feature tells TinyCadence to create contact properties of the HubSpot contact. This will happen upon contact creation, as well as before sending an email. (Note: we recommend enabling this if you want to use contact properties in your email templates)',

  [Messages.INSERT_TEMPLATE_VAR_TITLE]: 'Insert template variable',
  [Messages.INSERT_TEMPLATE_VAR_LABEL]: 'Contact Property',
  [Messages.INSERT_TEMPLATE_VAR_FALLBACK_LABEL]: 'Fallback',
  [Messages.INSERT_TEMPLATE_VAR_FALLBACK_HELP]: 'What to insert if the contact has no property',

  [Messages.CSV_UPLOAD_HEADER]: 'Upload CSV of contacts',
  [Messages.CSV_UPLOAD_DESCRIPTION]: 'Upload a CSV of 10MB or less with at least a column named "email". Other column names will be used as contact properties.',
  [Messages.CSV_UPLOAD_INVALID]: 'Invalid CSV',
  [Messages.CSV_UPLOAD_INVALID_NO_CONTENT]: 'CSV must have at least one header row and one row with data',
  [Messages.CSV_UPLOAD_INVALID_NO_EMAIL_COL]: 'CSV must have one column named "email"',
  [Messages.CSV_UPLOAD_INVALID_BAD_ROWS]: 'The following rows contain invalid email addresses: ',
  [Messages.CSV_UPLOAD_ERRORS]: 'There were some errors with the CSV enrollment. View the downloaded .csv file for details.',

  [Messages.CONTACT_DRAWER_TITLE]: 'Contact details',
  [Messages.CONTACT_DRAWER_LINK]: 'View contact',
  [Messages.CONTACT_DRAWER_HANDLES_TITLE]: 'About',
  [Messages.CONTACT_DRAWER_PROPERTIES_TITLE]: 'More details',

  [Messages.ONBOARDING_SKIP_TEXT]: 'Mark as done',
  [Messages.ONBOARDING_DONE_TEXT]: 'Done',
  [Messages.ONBOARDING_WELCOME_HEADER]: 'Welcome to TinyCadence!',
  [Messages.ONBOARDING_WELCOME_DESCRIPTION]: 'Let’s get you setup so TinyCadence can take all of the grunt work out of your inbox management.',
  [Messages.ONBOARDING_CONNECT_GOOGLE_HEADER]: 'Connect Gmail',
  [Messages.ONBOARDING_CONNECT_GOOGLE_DESCRIPTION]: 'First, you need to connect your Gmail account. TinyCadence uses your Gmail to send emails on your behalf, add labels to your threads, detect replies to emails that TinyCadence sent, and check your calendar for meetings with email recipients. TinyCadence does not read your emails or store your email data.',
  [Messages.ONBOARDING_INSTALL_ADDON_HEADER]: 'Install Gmail Add-On',
  [Messages.ONBOARDING_INSTALL_ADDON_DESC]: 'Next, install the TinyCadence Gmail Add-On so you can manage TinyCadence without leaving your inbox.',
  [Messages.ONBOARDING_CONNECT_CRM_HEADER]: 'Connect HubSpot',
  [Messages.ONBOARDING_CONNECT_CRM_DESCRIPTION]: 'Finally, connect your HubSpot account so TinyCadence can sync contacts and email sends directly to your CRM.',

  [Messages.CADENCE_SETTINGS_TITLE]: 'Cadence settings',
  [Messages.CADENCE_PERFORMANCE_TITLE]: 'Cadence performance',
  [Messages.CADENCE_EMAIL_SEND_ON_BIZ_DAYS]: 'Only send emails on business days',
  [Messages.CADENCE_EMAIL_SEND_DURING_BIZ_HOURS]: 'Customize the days and times for email sends',
  [Messages.CADENCE_EMAIL_SEND_AS_REPLIES]: 'Send all follow up emails as a reply in the thread',
  [Messages.CADENCE_EMAIL_PAUSE_BEFORE_SEND]: 'Require my approval and allow me to edit before sending an email',
  [Messages.CADENCE_SETTINGS_CREATED_AT]: 'Created',
  [Messages.CADENCE_SETTINGS_UPDATED_AT]: 'Updated',
  [Messages.CADENCE_EMAIL_UNENROLL_ON_REPLY]: 'Unenroll contacts who reply to an email from this cadence',
  [Messages.CADENCE_EMAIL_UNENROLL_ON_MTG]: 'Unenroll contacts who schedule meetings with me',
  [Messages.CADENCE_ENROLLMENT_OPTIONS_TITLE]: 'Cadence enrollment options',
  [Messages.CADENCE_ENROLLMENT_HS_LIST_TITLE]: 'Enroll contacts that are added to a HubSpot list',
  [Messages.CADENCE_ENROLLMENT_HS_LIST_DESC]: 'When a contact is added to a HubSpot list, automatically enroll them in this cadence.',
  [Messages.CADENCE_ENROLLMENT_HS_LIST_SYNC_NOW]: 'Sync now',
  [Messages.CADENCE_ENROLLMENT_HS_LIST_SYNC_SUCCESS]: 'Success, new contacts will be enrolled within 1 minute',
  [Messages.CADENCE_ENROLLMENT_HS_LIST_SYNC_TOO_FAST]: 'You must wait 5 minutes before re-syncing a HubSpot list to this cadence',

  [Messages.HOME_MERGE_CONTACTS_PROMPT]: 'seeing duplicate contacts?',
  [Messages.MERGE_CONTACTS_DIALOG_TITLE]: 'Merge two contacts',
  [Messages.MERGE_CONTACTS_DIALOG_DESC]: 'Perform this action if there are two contacts that you know to be the same person. Merging two contacts will combine their details, properties, and activity. If you are unsure of merging two individual contacts, do not perforrm thiis action.',
  [Messages.MERGE_CONTACTS_DIALOG_WARNING]: 'Double check the inputs above, this action cannot be undone',
  [Messages.MERGE_CONTACTS_DIALOG_PRIMARY_LABEL]: 'Primary contact',
  [Messages.MERGE_CONTACTS_DIALOG_SECONDARY_LABEL]: 'Secondary contact',

  [Messages.FREE_TRIAL_EXPIRED_TITLE]: 'Your account is suspended.',
  [Messages.FREE_TRIAL_EXPIRED_DESC]: 'Your trial expired, you closed your account, or your payment method failed. You can fix this from the billing page by updating your payment method. If this does not work or you have any questions, please contact support via the chat bubble in the bottom-right corner of the page.',
  [Messages.FREE_TRIAL_EXPIRED_BTN]: 'Go to billing',

  [Messages.CADENCE_EVENT_ENROLLED_TITLE]: 'Contact enrolled',
  [Messages.CADENCE_EVENT_ENROLLED_DESC]: 'enrollment started from',
  [Messages.CADENCE_EVENT_ENROLLED_FROM_GMAIL]: 'Gmail Add-On',
  [Messages.CADENCE_EVENT_ENROLLED_FROM_SITE]: 'TinyCadence website',
  [Messages.CADENCE_EVENT_ENROLLED_FROM_HS_LIST]: 'being added to a HubSpot list',

  [Messages.CADENCE_EVENT_HS_CONTACT_CREATE_TITLE]: 'Contact created in HubSpot',
  [Messages.CADENCE_EVENT_HS_CONTACT_CREATE_DESC]: 'Creation result:',
  [Messages.CADENCE_EVENT_HS_CONTACT_CREATE_SUCCESS]: 'success',
  [Messages.CADENCE_EVENT_HS_CONTACT_CREATE_ALREADY_EXISTS]: 'contact already existed',
  [Messages.CADENCE_EVENT_HS_CONTACT_CREATE_FAIL]: 'failed to create contact in HubSpot',

  [Messages.CADENCE_EVENT_PAUSED_TITLE]: 'Paused enrollment',
  [Messages.CADENCE_EVENT_PAUSED_DESC]: 'To await email send approval',
  [Messages.CADENCE_EVENT_PAUSED_DRAFT]: 'Draft email',

  [Messages.CADENCE_EVENT_HS_PROP_PULL_TITLE]: 'Refreshed properties',
  [Messages.CADENCE_EVENT_HS_PROP_PULL_DESC]: 'Pulled contact properties from HubSpot',

  [Messages.CADENCE_EVENT_EMAIL_OPEN_TITLE]: 'Email open',
  [Messages.CADENCE_EVENT_EMAIL_OPEN_DESC]: 'The contact opened the email in their inbox',

  [Messages.CADENCE_EVENT_EMAIL_SEND_TITLE]: 'Sent email',
  [Messages.CADENCE_EVENT_EMAIL_SEND_DESC]: 'To contact via Gmail',
  [Messages.CADENCE_EVENT_EMAIL_SEND_CONTENT]: 'Email content',
  [Messages.CADENCE_EVENT_EMAIL_SCHEDULED_TITLE]: 'Scheduled email send',

  [Messages.CADENCE_EVENT_SCHEDULED_TITLE]: 'Delay next step',

  [Messages.CADENCE_EVENT_HS_ACTIVITY_CREATE_TITLE]: 'Email activity created in HubSpot',
  [Messages.CADENCE_EVENT_HS_ACTIVITY_CREATE_DESC]: 'Creation result:',
  [Messages.CADENCE_EVENT_HS_ACTIVITY_CREATE_SUCCESS]: 'success',
  [Messages.CADENCE_EVENT_HS_ACTIVITY_CREATE_FAILED]: 'failed due to contact not found in HubSpot',

  [Messages.CADENCE_EVENT_AUTOMATED_UNENROLL_TITLE]: 'Automated unenroll',
  [Messages.CADENCE_EVENT_AUTOMATED_UNENROLL_DESC]: 'Unenrolled contact due to',
  [Messages.CADENCE_EVENT_AUTOMATED_UNENROLL_BC_EMAIL]: 'an email reply',
  [Messages.CADENCE_EVENT_AUTOMATED_UNENROLL_BC_MTG]: 'a scheduled meeting',

  [Messages.VIEW_ENROLLMENT_TITLE]: 'Enrollment for',
  [Messages.VIEW_ENROLLMENT_EVENTS_TIMELINE]: 'Enrollment timeline',

  [Messages.CADENCE_EVENT_FINISHED_TITLE]: 'Finished',
  [Messages.CADENCE_EVENT_FINISHED_DESC]: 'Contact reached the end of cadence steps',

  [Messages.CADENCE_EVENT_LABELLED_TITLE]: 'Applied label to thread',
  [Messages.CADENCE_EVENT_LABELLED_DESC]: 'Label was applied to Gmail thread.',

  [Messages.CADENCE_EVENT_CANCELLED_TITLE]: 'Cancelled enrollment',
  [Messages.CADENCE_EVENT_CANCELLED_DESC]: 'You manually cancelled this enrollment',

  [Messages.CONTACT_SUBNAV_SUMMARY]: 'Summary',
  [Messages.CONTACT_SUBNAV_EDIT]: 'Edit',
  [Messages.CONTACT_SUBNAV_METRICS]: 'Metrics',
  [Messages.CONTACT_SUBNAV_CADENCE_EVENTS]: 'Cadence events',

  [Messages.CONTACT_EVENTS_NO_EVENTS]: 'There are no events for this contact.',

  [Messages.SEND_TEST_EMAIL_BTN]: 'Send test email',
  [Messages.SEND_TEST_EMAIL_SUCCESS]: 'Sent test email to ',
  [Messages.SEND_TEST_EMAIL_FAIL]: 'Failed to send test email, please confirm Google integration',
  [Messages.SEND_EMAIL]: 'Send',
  [Messages.SAVING_DRAFT]: 'Saving',
  [Messages.SAVE_DRAFT_SUCCESS]: 'Saved changes',
  [Messages.SAVE_DRAFT_FAILED]: 'Save failed',

  [Messages.HUBSPOT_SETTINGS_TITLE]: 'Update your integration settings for HubSpot',
  [Messages.CADENCES_TITLE_LEARN_MORE]: 'What is a cadence?',
  [Messages.MORE_INFO]: 'More info',
  [Messages.ENROLL_DIALOG_HELP_LINK]: 'Learn more',
}

export default EN_US;